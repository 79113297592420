.tableContainer {
  background-color: var(--white);
  border: var(--border);
  border-radius: 8px;
  overflow: hidden;
}

.tableWrapper {
  border-bottom: 0.5px solid #22222220;
  max-width: 100%;
  overflow-x: auto;
}

.table {
  border-collapse: collapse;
  min-width: 100%;
}

.table th,
.table td {
  border-bottom: 0.5px solid #22222220;
  position: relative;
}

.table tr:last-child td {
  border-bottom: none;
}

.tablePlaceholderRow {
  background-color: var(--white);
  border-bottom: 0.5px solid #22222220;
  padding: 8px 16px;
}

.tablePlaceholderRow:last-child {
  border-bottom: none;
}

.tablePlaceholderRow div {
  min-width: 100%;
  height: 52px;
}