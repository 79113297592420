.notificationsPopoverBtn {
  background-color: var(--white);
  border: var(--border);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 44px;
  height: 44px;
}

.notificationsPopoverContainer .notificationsPopoverWrapper {
  visibility: hidden;
}

.notificationsPopoverContainer:hover .notificationsPopoverWrapper {
  visibility: visible;
}

.notificationsPopoverContainer {
  position: relative;
}

.notificationsPopoverWrapper {
  border-radius: 8px;
  position: absolute;
  top: 100%;
  right: -76px;
  width: 100svw;
  max-width: 380px;
  overflow: hidden;
  padding: 16px;
  z-index: 2;
}

.notificationsPopoverContent {
  background-color: var(--white);
  border: var(--border);
  border-radius: 8px;
  box-shadow: var(--shadow);
}

.notificationsPopoverHead {
  box-shadow: 0px 1px 2px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}

.notificationsPopoverHead div {
  font-weight: 600;
  font-size: 1.2rem;
}

.notificationsPopoverBody {
  min-height: 180px;
  max-height: min(460px, calc(100svh - 216px));
  overflow-y: scroll;
}

.notificationsPopoverFooter {
  border-radius: 0 0 8px 8px;
  background-color: var(--white);
  box-shadow: 0px -1px 2px 0 rgba(0, 0, 0, 0.1);
  padding: 16px;
}

.notificationsPopoverLink {
  background-color: var(--dark-blue);
  border-radius: 4px;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 44px;
  text-align: center;
}

.notificationsPopoverBody p {
  border-top: 1px solid #dcdcdc;
  color: var(--medium);
  font-size: 14px;
  padding: 16px;
  text-align: center;
}