.socialLink {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  line-height: 24px;
  margin-bottom: 8px;
}

.socialLinkIcon {
  background-color: var(--dark-blue);
  border-radius: 50%;
  border: var(--border);
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  min-height: 24px;
}

.socialLink span {
  color: var(--dark-blue);
  font-weight: 600;
  white-space: nowrap;
  word-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}