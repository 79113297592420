.shimmer {
  background-color: #22222220;
  border-radius: 4px;
  position: relative;
  width: fit-content;
  overflow: hidden;
}

.shimmer::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(120deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0) 100%);
  top: 0;
  left: 0;
  transform: translateX(-100%);
  animation: shimmer 1s infinite linear;
}

.shimmer>* {
  visibility: hidden;
}

.skeleton>* {
  color: transparent;
  user-select: none;
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}