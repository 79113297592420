.pageTab {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  padding: 8px 16px;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.3s;
}

.pageTab:hover {
  background-color: #22222220;
}

.pageTab.active {
  background-color: #22222210;
}

.pageTabsWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}

.pageTabs hr {
  width: 100%;
  margin: 24px 0;
  border: none;
  border-top: 0.5px solid #22222220;
}