.tableBody td {
  background-color: var(--white);
  font-size: 0.9rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  padding: 4px 16px;
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
}

.tableBody td:nth-child(1),
.tableBody td:nth-child(2) {
  text-align: start;
}

.tableBody tr:hover td {
  background-color: #001d6805;
}