.questionCard {
  margin-bottom: 16px;
}

.questionCardQuestion {
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr 40px;
  grid-template-rows: 1fr;
  align-items: center;
  gap: 16px;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 8px 16px;
}

.questionCardQuestion div:last-child {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}

.questionCardQuestion:hover {
  background-color: var(--light-blue);
  cursor: pointer;
}

.questionCardQuestion:hover div:last-child {
  background-color: var(--light-blue);
  border-radius: 50%;
}

.questionCardAnswer {
  padding: 8px 16px;
}