.searchModalContainer {
  border-bottom: 1px solid #e9e9e980;
  background-color: var(--lightest);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  width: 100%;
  padding: 16px;
}

.cancelSearchBtn {
  border: 1px solid var(--dark-blue);
  background-color: var(--white);
  border-radius: 8px;
  color: var(--dark-blue);
  cursor: pointer;
  font-weight: 600;
  padding: 10px 16px;
}