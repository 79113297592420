.progressCard {
  width: 100%;
  margin-bottom: 16px;
}

.progressCard:last-child {
  margin-bottom: 0;
}

.progressCardLabel {
  color: var(--medium);
  font-size: 0.8rem;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.progressCardValues {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.progressCardValues div {
  font-size: 0.9rem;
  font-weight: 600;
}

.progressCardBar {
  border-radius: 16px;
  width: 100%;
  height: 10px;
  margin: 4px 0;
  position: relative;
  overflow: hidden;
}

.progressCardBarZ1 {
  border-radius: 16px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.progressCardBarZ0 {
  background-color: var(--lightest);
  border: 1px solid var(--light);
  border-radius: 16px;
  height: 100%;
}

.progressCardLock {
  position: relative;
}

.progressCardLock:hover::before {
  content: "Privacy enabled until voting ends";
  position: absolute;
  top: 0%;
  left: 0%;
  transform: translate(calc(-100% + 18px), calc(-100% - 6px));
  background-color: var(--black);
  border-radius: 4px;
  color: var(--white);
  width: 120px;
  font-size: 14px;
  font-weight: 400;
  text-wrap: wrap;
  padding: 6px 12px;
  z-index: 2;
}