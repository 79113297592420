.bookmarkBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  position: relative;
}

.bookmarkBtn .iconDefault,
.bookmarkBtn .iconMarked {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bookmarkBtn .iconDefault svg {
  fill: none;
}

.bookmarkBtn .iconDefault svg path {
  stroke: var(--medium);
}

.bookmarkBtn .iconMarked svg {
  fill: var(--yellow);
}

.bookmarkBtn .iconMarked svg path {
  stroke: var(--yellow);
}

.bookmarkBtn:hover svg {
  fill: var(--blue);
}

.bookmarkBtn:hover svg path {
  stroke: var(--blue);
}

@media screen and (min-width: 968px) {
  .bookmarkBtn:hover::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translate(14px, -50%);
    background-color: var(--black);
    border-radius: 4px;
    color: var(--white);
    width: 120px;
    font-size: 14px;
    font-weight: 400;
    text-wrap: wrap;
    padding: 6px 12px;
    z-index: 2;
  }

  .bookmarkBtn.add:hover::before {
    content: "Add DAO to your watchlist";
  }

  .bookmarkBtn.remove:hover::before {
    content: "Remove DAO from watchlist";
  }

  .bookmarkBtn:hover::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translate(4px, -50%);
    border: 6px solid transparent;
    border-right-color: var(--black);
    z-index: 1;
  }
}