.contentCard {
  background-color: var(--white);
  border-radius: 8px;
  border: var(--border);
  padding: 16px;
}

.contentCardMetricLink {
  display: grid;
  grid-template-columns: 1fr 32px;
  grid-template-rows: 1fr;
  gap: 0;
  align-items: center;
}

.contentCardTitle {
  color: var(--medium);
  font-size: 1rem;
  font-weight: 500;
  line-height: 1;
}

.contentCardValue {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  margin-top: 8px;
}

.contentCardIcon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.contentCardNetwork {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.contentCardVoting {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.contentCardVoting .contentCardItems {
  display: flex;
  flex-wrap: nowrap;
  gap: 6px;
}

.contentCardLabel,
.contentCardLabelLink,
.contentCardDuration {
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
  justify-content: space-between;
}

.contentCardLabel .contentCardValue,
.contentCardLabelLink .contentCardValue {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1;
  margin: 0px;
}

.contentCardDurationValues {
  background-color: var(--white);
  border-radius: 8px;
  border: var(--border);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
}

.contentCardDurationValue {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  text-align: center;
}

.contentCardDurationValue:first-child {
  border-right: 1px solid #e9e9e9;
}

.contentCardDurationValue span {
  color: var(--medium);
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.25px;
  text-transform: uppercase;
}

.contentCardDurationValue span {
  width: 100%;
  padding: 2px 4px;
}

.contentCardDurationValue:first-child span {
  background-color: #00800015;
}

.contentCardDurationValue:last-child span {
  background-color: #ca000015;
}

.contentCardDurationDate {
  padding: 8px 12px 12px 12px;
}

.contentCardDurationDate div:first-child {
  font-size: 12.5px;
  font-weight: 700;
  text-wrap: nowrap;
  text-align: center;
}

.contentCardDurationDate div:last-child {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}

.contentCardLabelLink {
  color: var(--dark-blue);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  font-size: 0.95rem;
  font-weight: 500;
  text-align: right;
}

.contentCardLabelLink:hover {
  color: var(--blue);
}

.contentCardItemGreen,
.contentCardItemRed {
  border-radius: 4px;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  gap: 2px;
  padding: 4px;
}

.contentCardItemRed {
  background-color: #ca000020;
  color: #ca0000;
}

.contentCardItemRed span {
  color: #ca0000;
}

.contentCardItemGreen {
  background-color: #22762220;
  color: #227622;
}

.contentCardItemGreen span {
  color: #227622;
}