.wrapper {
  max-width: 798px;
  margin-inline: auto;
  padding: 64px 16px;
}

.wrapper h1 {
  font-size: clamp(1.6rem, 8svw, 2.4rem);
  margin-bottom: 48px;
  text-align: center;
}