.pageCrumbs {
  background-color: var(--white);
  border-bottom: var(--border);
}

.pageCrumbsWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: var(--max-width);
  margin-inline: auto;
  padding: 12px 16px;
}

.pageCrumb {
  display: flex;
  align-items: center;
  gap: 8px;
}

.pageCrumbLink {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: var(--dark-blue);
  max-width: 120px;
  display: block;
  white-space: nowrap;
  word-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pageCrumbLink:hover {
  color: var(--blue);
}

.pageCrumbLink:last-child {
  color: var(--black);
  cursor: default;
}

.pageCrumbLink:last-child:hover {
  text-decoration: none;
}