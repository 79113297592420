.tableValueEns,
.tableValueLink {
  display: flex;
  align-items: center;
}

.tableValueLink {
  gap: 6px;
}

.tableValueEns {
  gap: 4px;
  max-width: 160px;
}

.tableValueEns div,
.tableValueEns span,
.tableValueLink div,
.tableValueLink span {
  color: var(--dark-blue);
  font-size: 1rem;
}

.tableValueEns:hover div,
.tableValueEns:hover span,
.tableValueLink:hover div,
.tableValueLink:hover span {
  color: var(--blue);
}

.tableValueEns svg {
  min-width: 16px;
  min-height: 16px;
}

.tableValueEns:hover svg {
  fill: var(--blue);
}

.tableValueEncrypted {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}