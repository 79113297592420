.settings {
  width: 100%;
  min-height: calc(100svh - 80px);
}

.settings h4 {
  text-transform: capitalize;
}

.settingsContainer {
  max-width: 798px;
  margin: 0 auto;
  padding: 64px 16px;
}

.settingsUser {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  margin-bottom: 64px;
}

.settingsContent {
  min-width: 0;
}

.settingsContent hr {
  width: 100%;
  margin: 24px 0;
  border: none;
  border-top: 0.5px solid #22222220;
}

.settingsContent p {
  color: var(--medium);
}

/* Account settings */
.settingsContentIdentities {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;
}

.settingsUser h2 {
  margin-bottom: 2px;
}

.settingsUser p {
  color: var(--medium);
}

/* Notifications settings */
.checkmarkContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 1rem;
  line-height: 26px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkmarkContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: var(--light);
  border-radius: 4px;
}

.checkmarkContainer input:checked~.checkmark {
  border: var(--border);
  background-color: var(--blue);
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkmarkContainer input:checked~.checkmark:after {
  display: block;
}

.checkmarkContainer .checkmark:after {
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid var(--white);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.updateSettingsBtn {
  border-radius: 100px;
  background-color: var(--dark-blue);
  color: var(--white);
  font-weight: 600;
  cursor: pointer;
  padding: 12px 24px;
}

/* Aside */
.aside {
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 16px;
  margin-bottom: 40px;
}

.asideTab,
.asideTabActive,
.aside button {
  font-size: 1.2rem;
  text-align: left;
}

.asideTab,
.aside button {
  color: var(--medium);
  font-weight: 400;
}

.asideTabActive {
  border-bottom: 2px solid var(--black);
  color: var(--black);
  font-weight: 500;
}

.aside hr {
  display: none;
  width: 100%;
  margin-bottom: 24px;
  border: none;
  border-top: 0.5px solid #22222220;
}

@media screen and (min-width: 468px) {
  .settingsWrapper {
    display: grid;
    grid-template-columns: minmax(140px, 1fr) 3fr;
    grid-row: 1fr;
    gap: 40px;
  }

  .aside {
    display: flex;
    flex-direction: column;
    gap: 0;
    min-width: 0;
    margin-bottom: 0;
  }

  .asideTab,
  .asideTabActive,
  .aside button {
    width: 100%;
    margin-bottom: 24px;
  }

  .asideTabActive {
    border-bottom: none;
  }

  .aside hr {
    display: block;
  }
}

@media screen and (min-width: 768px) {
  .settingsWrapper {
    grid-template-columns: minmax(180px, 1fr) 3fr;
    gap: 80px;
  }
}