.searchInputContainer {
  width: 100%;
  position: relative;
}

.searchInput {
  background-color: var(--white);
  border: var(--border);
  border-radius: 50px;
  display: grid;
  grid-template-columns: 18px 1fr 18px;
  grid-template-rows: 1fr;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  padding: 0px 24px;
}

.searchInput input {
  color: var(--black);
  width: 100%;
  padding: 12px 0;
}

.searchInput span {
  width: 18px;
}

.searchInput svg {
  cursor: pointer;
}

.searchResultsContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: absolute;
  top: 100%;
  width: 100%;
  padding: 8px 0;
  z-index: 2;
}

.searchResults {
  background-color: var(--white);
  border: var(--border);
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 8px;
}

.searchResult {
  display: flex;
  align-items: center;
  gap: 8px;
  width: calc(100% - 32px);
  margin-bottom: 4px;
  padding: 8px 16px;
}

.searchResult span {
  white-space: nowrap;
  word-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.searchResultsTitle {
  border-bottom: var(--border);
  margin-bottom: 8px;
  color: var(--medium);
  letter-spacing: 0.25px;
  font-size: 12px;
  font-weight: 700;
  white-space: nowrap;
  word-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  padding: 0 8px 8px 0;
}

.searchResultsMsg {
  color: var(--medium);
  font-size: 0.9rem;
  padding: 8px 16px;
}

.searchResult:hover {
  background-color: var(--light-blue);
  border-radius: 8px;
  color: var(--blue);
  cursor: pointer;
}

.searchResult:last-child {
  margin-bottom: 0;
}

.searchBtn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
}

@media screen and (min-width: 768px) {
  .searchInput {
    width: auto;
  }

  .searchInput input {
    min-width: 228px;
    width: auto;
  }
}