.copyAddr {
  background-color: var(--white);
  border: var(--border);
  border-radius: 8px;
  display: inline-grid;
  grid-template-columns: 1fr 20px;
  grid-row: 1fr;
  align-items: center;
  gap: 8px;
  width: fit-content;
  padding: 8px 16px;
}

.copyAddr span {
  display: block;
  white-space: nowrap;
  word-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}