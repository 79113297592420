.smNotificationCard {
  border-top: 1px solid #dcdcdc;
  display: flex;
  gap: 8px;
  padding: 8px 16px;
}

.smNotificationCardImg {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.smNotificationCardImg img {
  border-radius: 50%;
  width: 24px;
  height: 24px;
}

.smNotificationCardContent {
  width: 100%;
  max-width: calc(100% - 40px);
}

.smNotificationCardContent {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
}

.smNotificationCardAsub {
  font-size: 14.5px;
  line-height: 1.2;
  color: var(--black);
  font-weight: 600;
}

.smNotificationCardAmsg {
  font-size: 14px;
  line-height: 1.2;
  color: var(--black);
}

.smNotificationCardTime {
  font-size: 12px;
  line-height: 1.2;
  color: var(--medium);
  font-weight: 500;
}

@media screen and (min-width: 468px) {}