.alertModal {
  background-color: var(--white);
  border: var(--border);
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 16px;
  right: 16px;
  z-index: 2;
}

.alertModal div:first-child {
  display: grid;
  grid-template-columns: 16px 1fr 40px;
  grid-template-rows: 1fr;
  grid-gap: 4px;
  align-items: center;
  justify-items: flex-end;
  font-size: 15px;
  padding: 16px;
}

.alertModal div:first-child svg:last-child {
  cursor: pointer;
}