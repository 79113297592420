.tableHead th {
  background-color: var(--white);
  color: var(--medium);
  font-size: 0.9rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  padding: 16px;
  white-space: nowrap;
  text-align: center;
}

.tableHead th:nth-child(1),
.tableHead th:nth-child(2) {
  text-align: start;
}