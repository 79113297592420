.footer {
  border-top: 0.5px solid;
  border-image-slice: 1;
  border-width: 0.5px;
  border-image-source: var(--border-image-source);
}

.footerWrapper {
  max-width: var(--max-width);
  margin-inline: auto;
  padding: 32px 16px;
}

.footer h3 {
  color: var(--medium);
  letter-spacing: 0.25px;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 8px;
}

.footerNavigation {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 32px;
  align-items: flex-start;
  align-content: flex-start;
  margin-bottom: 40px;
}

.footerNavigation div {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.footerNavigation a {
  font-size: 1rem;
  font-weight: 400;
}

.footerNavigation a:hover {
  color: var(--blue);
  text-decoration: underline;
}

.footerDisclaimer {
  font-size: 0.9rem;
  line-height: 1.4;
}

.footer hr {
  margin: 24px 0px;
  border: none;
  border-top: 0.5px solid #22222220;
}

.footerNote div {
  color: var(--medium);
  font-size: 0.9rem;
  text-align: center;
}

.footerNote a {
  color: var(--dark-blue);
  font-weight: 500;
}

.footerNote a:hover {
  color: var(--blue);
}

@media screen and (min-width: 568px) {
  .footerNote {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 16px;
  }

  .footerNote div:first-child {
    text-align: left;
  }

  .footerNote div:last-child {
    text-align: right;
  }
}